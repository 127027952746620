import axios from 'axios';
import canUseDOM from '@lib/canUseDOM';

export const windowHost = () => (canUseDOM ? window.location.hostname : '');
export const isProd = () => !local && !preprod;
export const local = windowHost().indexOf('localhost') !== -1;
export const preprod = windowHost().indexOf('preprod') !== -1;

const apiUrl =
  canUseDOM && (local || preprod)
    ? 'https://bp-preprod.askzeta.com'
    : 'https://card-pt.askzeta.com';

export const nextUrl =
  canUseDOM && (local || preprod)
    ? 'https://next-preprod.askzeta.com'
    : 'https://next.askzeta.com';

const headers = {
  'Content-Type': 'application/json',
  Authorization: null,
};

export const getPendingInvitation = async ({ token }) => {
  const result = await axios.post(
    `${apiUrl}/graphql`,
    {
      query: `query pendingInvitation($claimToken: String!) {
      pendingInvitation(claimToken: $claimToken) {
        accountMembers {
          firstName
        }
      }
    }`,
      variables: {
        claimToken: token,
      },
    },
    {
      withCredentials: true,
      headers: headers,
    },
  );
  return result.data?.data?.pendingInvitation;
};
