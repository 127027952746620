import React from 'react';
import { Box, Flex, Text, Divider, useMediaQuery } from '@chakra-ui/core';
import moment from 'moment';
import styled from 'styled-components';

import ContentContainer from '../components/ContentContainer';
import {
  APP_CONTENT_DESKTOP_WIDTH,
  APP_CONTENT_MOBILE_WIDTH,
  APP_CONTENT_SMALL_DESKTOP_WIDTH,
} from '../styles/sizes';
import Link from '../Navigation/Link';
import theme, { colors } from '@theme';
import {
  Facebook,
  Instagram,
  Pinterest,
  YouTube,
  Twitter,
  Reddit,
  TikTok,
} from '../components/SocialIcons';
import NewsLetterSignup from '../components/home/newsLetterSignup';
import { AppStoreBadge } from '@zeta';

import { CURRENT_APY_RATE, CURRENT_VIP_APY_RATE } from '../lib/consts';
import { justifyContent } from 'styled-system';

export const DesktopOnly = styled.div`
  @media (max-width: 62em) {
    display: none;
  }
`;
export const MobileOnly = styled.div`
  @media (min-width: 62em) {
    display: none;
  }
`;

export default function Footer({ noSubscribe = false }) {
  const [isMobile] = useMediaQuery(`(max-width: ${APP_CONTENT_SMALL_DESKTOP_WIDTH})`);
  const [isSmallestBreakpoint] = useMediaQuery(
    `(max-width: ${APP_CONTENT_MOBILE_WIDTH})`,
  );

  return (
    <>
      {!noSubscribe && (
        <Flex bg={colors.greenLight} className="news-letter-signup">
          <ContentContainer maxWidth={APP_CONTENT_DESKTOP_WIDTH}>
            <NewsLetterSignup />
          </ContentContainer>
        </Flex>
      )}
      <Box bg={colors.black} paddingX={[4, 6, 6, 12]} paddingTop={[4, 6, 6, 8]}>
        <ContentContainer maxWidth={APP_CONTENT_DESKTOP_WIDTH} minWidth={'auto'}>
          <Flex
            flexDirection={['column', 'column', 'row', 'row']}
            flexWrap={['unset', 'unset', 'wrap', 'nowrap']}
            justifyContent={['unset', 'unset', 'space-between', 'space-between']}
            pb={6}
            mb={6}
            px={[0]}
          >
            <Box display={['flex', 'flex', 'none', 'none']} width={'100%'}>
              <Link to={'/aboutus'} noNewTab>
                <Text
                  p={[1, 0]}
                  color={colors.white}
                  fontWeight={theme.fontWeights.semibold}
                  as="p"
                  mb={3}
                >
                  About
                </Text>
              </Link>
            </Box>
            <Flex
              justify={['flex-start', 'flex-start', 'center', 'center']}
              flexDirection={['row', 'row', 'column', 'column']}
              flexWrap={['wrap', 'wrap', 'nowrap', 'nowrap']}
            >
              <Box display={['none', 'none', 'block', 'block']}>
                <Link to={'/aboutus'} noNewTab>
                  <Text
                    p={[1, 0]}
                    color={colors.white}
                    fontWeight={theme.fontWeights.semibold}
                    as="p"
                    mb={3}
                  >
                    About
                  </Text>
                </Link>
              </Box>
              <Link to={'/diversity'} noNewTab>
                <Text p={[1, 1, 0, 0]} color={colors.white} as="p" mb={3}>
                  Diversity{isMobile ? ` | ` : null}
                </Text>
              </Link>
              <Link to={'/press'} noNewTab>
                <Text p={[1, 1, 0, 0]} color={colors.white} as="p" mb={3}>
                  Press{isMobile ? ` | ` : null}
                </Text>
              </Link>
              <Link to={'/aboutus'} noNewTab>
                <Text p={[1, 1, 0, 0]} color={colors.white} as="p" mb={3}>
                  Team{isMobile ? ` | ` : null}
                </Text>
              </Link>
              <Link to={'/careers'} noNewTab>
                <Text p={[1, 1, 0, 0]} color={colors.white} as="p" mb={3}>
                  Careers{isMobile ? ` | ` : null}
                </Text>
              </Link>
              <Link to={'/legal'} noNewTab>
                <Text p={[1, 1, 0, 0]} color={colors.white} as="p" mb={3}>
                  Legal{isMobile ? ` | ` : null}
                </Text>
              </Link>
              <Link to={'/security'} noNewTab>
                <Text p={[1, 1, 0, 0]} color={colors.white} as="p" mb={3}>
                  Security
                </Text>
              </Link>
            </Flex>

            <Box
              display={['flex', 'flex', 'none', 'none']}
              width={'100%'}
              paddingTop={'24px'}
            >
              <Link href={'/magazine'} noNewTab>
                <Text
                  p={[1, 1, 0, 0]}
                  color={colors.white}
                  fontWeight={theme.fontWeights.semibold}
                  as="p"
                  mb={6}
                >
                  Magazine
                </Text>
              </Link>
            </Box>
            <Box
              display={['flex', 'flex', 'block', 'block']}
              flexWrap={['wrap', 'wrap', 'nowrap', 'nowrap']}
            >
              <Box display={['none', 'none', 'block', 'block']}>
                <Link href={'/magazine'} noNewTab>
                  <Text
                    p={[1, 1, 0, 0]}
                    color={colors.white}
                    fontWeight={theme.fontWeights.semibold}
                    as="p"
                    mb={3}
                  >
                    Magazine
                  </Text>
                </Link>
              </Box>
              <Link href={'/magazine/articles'} noNewTab>
                <Text p={[1, 1, 0, 0]} color={colors.white} as="p" mb={3}>
                  Articles{isMobile ? ` | ` : null}
                </Text>
              </Link>
              <Link href={'/magazine/#guides-quizzes-tools-podcast'} noNewTab>
                <Text p={[1, 1, 0, 0]} color={colors.white} as="p" mb={3}>
                  {isMobile ? `Guides | ` : `Guides and Tools`}
                </Text>
              </Link>
              <Link href={'/magazine/podcast'} noNewTab>
                <Text p={[1, 1, 0, 0]} color={colors.white} as="p" mb={3}>
                  Podcast{isMobile ? ` | ` : null}
                </Text>
              </Link>
              <Link to={'/sentwithlove'} noNewTab>
                <Text p={[1, 1, 0, 0]} color={colors.white} as="p" mb={3}>
                  #SentWithLove
                </Text>
              </Link>
            </Box>

            <Box
              display={['flex', 'flex', 'none', 'none']}
              width={'100%'}
              paddingTop={'24px'}
            >
              <Link to={'/help'} noNewTab>
                <Text
                  p={[1, 1, 0, 0]}
                  color={colors.white}
                  fontWeight={theme.fontWeights.semibold}
                  as="p"
                  mb={6}
                >
                  Support
                </Text>
              </Link>
            </Box>

            <Box
              display={['flex', 'flex', 'block', 'block']}
              flexWrap={['wrap', 'wrap', 'nowrap', 'nowrap']}
              paddingRight={5}
            >
              <Box display={['none', 'none', 'block', 'block']} width={'100%'}>
                <Link to={'/help'} noNewTab>
                  <Text
                    p={[1, 1, 0, 0]}
                    color={colors.white}
                    fontWeight={theme.fontWeights.semibold}
                    as="p"
                    mb={3}
                  >
                    Support
                  </Text>
                </Link>
              </Box>

              <Link to={'/help'} noNewTab>
                <Text p={[1, 1, 0, 0]} color={colors.white} as="p" mb={3}>
                  FAQs{isMobile ? ` | ` : null}
                </Text>
              </Link>
              <Link href={'/mailto:support@askzeta.com'} noNewTab>
                <Text p={[1, 1, 0, 0]} color={colors.white} as="p" mb={3}>
                  support@askzeta.com{isMobile ? ` | ` : null}
                </Text>
                <Box display={['block', 'block', 'none', 'none']}>&nbsp;|&nbsp;</Box>
              </Link>
              <Link href={'https://card.askzeta.com/messages'} noNewTab>
                <Text p={[1, 1, 0, 0]} color={colors.white} as="p" mb={3}>
                  Secure Chat
                </Text>
              </Link>
            </Box>

            <Flex
              flexDirection={['row', 'row', 'column', 'column']}
              pt={[6, 8, 0, 0]}
              justify={['center', 'center', 'flex-start', 'flex-start']}
              style={{ gap: isMobile ? 0 : '12px' }}
            >
              <Flex height={['30px', '50px', null, null]}>
                <AppStoreBadge
                  zjcDirect
                  original
                  height={isSmallestBreakpoint ? 40 : isMobile ? 50 : undefined}
                  platform="android"
                  display={isMobile ? 'flex' : 'inline-block'}
                  linkStyle={{ justifyContent: isMobile ? 'flex-start' : 'undefined' }}
                />
              </Flex>
              <Flex pt={[0, 0, 5, 5]}>
                <AppStoreBadge
                  zjcDirect
                  original
                  height={isSmallestBreakpoint ? 40 : isMobile ? 50 : undefined}
                  platform="ios"
                  display={isMobile ? 'flex' : 'inline-block'}
                  linkStyle={{ justifyContent: isMobile ? 'flex-end' : 'undefined' }}
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex direction={['column', 'column', 'row', 'row']}>
            <Flex
              mb={6}
              flexDirection="column"
              justifyContent={['center', 'center', 'unset', 'unset']}
              width={['100%', '100%', 'unset', 'unset']}
            >
              <Text
                color={colors.white}
                fontWeight={theme.fontWeights.semibold}
                textAlign={['center', 'center', 'left', 'left']}
              >
                Join Our Community
              </Text>
              <Flex
                paddingTop="24px"
                justifyContent={['center', 'center', 'flex-start', 'flex-start']}
                width={['100%', '100%', 'unset', 'unset']}
                style={{ gap: '24px' }}
              >
                <Box>
                  <YouTube />
                </Box>
                <Box>
                  <Instagram />
                </Box>
                <Box>
                  <Facebook />
                </Box>
                <Box>
                  <Twitter />
                </Box>
                <Box>
                  <Pinterest />
                </Box>
                <Box>
                  <Reddit />
                </Box>
                <Box>
                  <TikTok />
                </Box>
              </Flex>
            </Flex>

            <Flex
              width="100%"
              justify="flex-end"
              align={['center', 'center', 'flex-end', 'flex-end']}
              direction="column"
            >
              <Text
                textAlign={['center', 'center', 'right', 'right']}
                color={colors.white}
              >
                © {moment.utc().format('YYYY')} Zeta Help Inc. All rights reserved.
              </Text>
              <Text
                textAlign={['center', 'center', 'right', 'right']}
                color={colors.white}
                paddingTop={'24px'}
              >
                2261 Market Street #4718 <br />
                San Francisco, CA 94114
              </Text>
            </Flex>
          </Flex>

          <Divider pb={6} mb={6} color={colors.darkGray} />
          <Box px={[2, 2, 0, 0]}>
            <Text
              // id="zeta-disclosure"
              as="p"
              color={colors.gray}
              fontSize={theme.fontSizes.xs}
              lineHeight={theme.lineHeights['5.5']}
            >
              To safely consume this site, we recommend reading this disclaimer. Any
              outbound links will take you away from Zeta, to external sites in the world
              wide web. Just so you know, Zeta doesn’t endorse any linked websites nor do
              we pay anyone to appear on here. Any reference to prices on the site are
              just estimates; actual prices are up to specific merchants and their current
              desire to charge you for things. Also, nothing on this website should be
              construed as investment advice. We’re here to share our favorite tools,
              tactics and tips for managing your money together. This content is for your
              responsible consumption. Please don’t see this as a recommendation to buy
              specific investments or go on a crypto-binge. Lastly, we 100% believe that
              personal finance is exactly that, personal. We may sometimes publish content
              on this website that has been created by affiliated or unaffiliated partners
              such as employees, advisors or writers. Unless we explicitly say so, these
              post do not necessarily represent the actual views or opinions of Zeta.
              <br />
              <br />
              By using this website, you understand the content presented is provided for
              informational purposes only and agree to our{' '}
              <Link
                style={{
                  color: colors.iceberg,
                  textDecoration: 'underline',
                }}
                href="/terms-of-use"
              >
                {' '}
                Terms of Use
              </Link>{' '}
              and
              <Link
                style={{
                  color: colors.iceberg,
                  textDecoration: 'underline',
                }}
                href="/privacy-policy"
              >
                {' '}
                Privacy Policy
              </Link>
              .
              <br />
              <br />
              <sup>1</sup>Zeta is a financial technology company, not a bank. Banking
              services provided by Piermont Bank; Member FDIC. All deposit accounts of the
              same ownership and/or vesting held at the issuing bank are combined and
              insured under an FDIC Certificate of $250,000 per depositor. The Zeta
              Mastercard® Debit Card is issued by Piermont Bank, Member FDIC, pursuant to
              license by Mastercard International Incorporated and can be used everywhere
              Mastercard is accepted.
              <br />
              <br />
              <sup id="zeta-disclosure">2</sup>Zeta Annual Percentage Yield (APY) is
              effective as of 05/01/2023, for customers who qualify for VIP status.
              Minimum amount to open an account is $0.00. Minimum balance to earn the APY
              is $0.01. Interest rates are as follows: {CURRENT_VIP_APY_RATE} APY applies
              to the entire balance for customers who qualify for VIP status. Interest
              rates may change after the account is opened. Fees may reduce earnings.
            </Text>
            <br />
            <br />
          </Box>
        </ContentContainer>
      </Box>
    </>
  );
}
