import React from 'react';
import { Flex, Text, Box } from '@chakra-ui/core';

import { colors } from '@theme';
import ContentContainer from '../components/ContentContainer';
import Link from './Link';
import LogoLink from './LogoLink';
import GetStartedBtn from './GetStartedButton';
import { APP_CONTENT_WIDE_WIDTH } from '../styles/sizes';
import { useLocation } from '@reach/router';

const LargeNav = props => {
  const { homeNav, lightMode, legal, showGetStartedDesktop, bgColor } = props;
  const { pathname } = useLocation();

  return (
    <Box
      paddingY={['1rem', '1rem']}
      paddingX={[0, '1rem']}
      as="nav"
      position="fixed"
      backgroundColor={bgColor}
      style={{
        top: 0,
        width: '100%',
        zIndex: 100,
      }}
    >
      <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
        <Flex direction="row" justify="space-between">
          <Flex justify="flex-start" align="center">
            <LogoLink homeNav={homeNav} lightMode={lightMode} legal={legal} />
            <Box marginLeft={'6rem'}>
              <Link timeout={0} noNewTab to="/joint-bank-account">
                <Text as="span" fontWeight="600" color={colors.primary} fontSize="16px">
                  Accounts
                </Text>
              </Link>
            </Box>
            <Box marginLeft={'4rem'}>
              <Link timeout={0} noNewTab to={'/zeta-plus'}>
                <Text as="span" fontWeight="600" color={colors.primary} fontSize="16px">
                  Zeta+
                </Text>
              </Link>
            </Box>
            <Box marginLeft={'4rem'}>
              <Link timeout={150} noNewTab href={'/magazine'}>
                <Text as="span" fontWeight="600" color={colors.primary} fontSize="16px">
                  Magazine
                </Text>
              </Link>
            </Box>
          </Flex>
          {showGetStartedDesktop && (
            <Flex>
              <Flex align="center" justify="flex-end">
                <GetStartedBtn />
              </Flex>
            </Flex>
          )}
        </Flex>
      </ContentContainer>
    </Box>
  );
};
export default LargeNav;
