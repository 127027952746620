import { HIVE_API } from '@lib/platform';
const query = `
  mutation upsertSubscriber($input: UpsertSubscriberInput!) {
    upsertSubscriber(input: $input) {
      ok
    }
  }
`;

const subscribe = async payload => {
  try {
    let response = await fetch(HIVE_API, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        variables: {
          input: payload || {},
        },
        operationName: 'upsertSubscriber',
        query: query,
      }),
    });
  } catch (e) {
    console.log(e);
  }
};

export default subscribe;
